<template>
    <Modal @close="sendCloseEvent" class="login-form -center">
        <template v-slot:modal-header-button-start>
            <button @click="sendCloseEvent" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>
        <template v-slot:header-content>
            <Logo />
        </template>

        <h1>{{ $t("Welcome back") }}</h1>

        <form @submit.prevent="sendLoginRequest">
            <div class="field">
                <div :class="`input ${validation.email ? 'has-error' : ''}`">
                    <div class="input-content">
                        <input
                            @change="onEmailInput"
                            v-model="email"
                            name="email"
                            type="email"
                            :placeholder="$t('Email')"
                            class="input"
                        />
                    </div>
                </div>
            </div>
            <div class="field">
                <div :class="`input ${validation.password ? 'has-error' : ''}`">
                    <div class="input-content">
                        <input
                            @change="onPasswordInput"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            name="password"
                            :placeholder="$t('Password')"
                        />
                    </div>
                    <button
                        type="button"
                        class="show-password"
                        @click="showPassword = !showPassword">
                            {{
                                !showPassword
                                ? $t("Show password")
                                : $t("Hide password")
                            }}
                        <iconEye v-if="!showPassword" />
                        <iconEyeOff v-else />
                    </button>
                </div>
            </div>
            <div class="actions">
                <button type="submit" class="btn -theme -lg">
                    {{ $t("Login") }}
                </button>
            </div>
        </form>
        <div class="actions">
            <button class="forgot-password-link" @click="forgotPasswordModelOpen = true">
                {{ $t("Forgot password?") }}
            </button>
        </div>
    </Modal>
    <ForgotPasswordModal @close="forgotPasswordModelOpen = false" v-if="forgotPasswordModelOpen" />
</template>

<script>
import Authentication from "@/lib/api/Authentication";
import Modal from "@/components/Modal";
import LocalStorage from "@/lib/LocalStorage";
import Validator from "@/lib/Validator";

import ForgotPasswordModal from "../ForgotPasswordModal";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import iconEye from "@/assets/icons/icon-eye.svg?inline";
import iconEyeOff from "@/assets/icons/icon-eye-off.svg?inline";
import Logo from "@/assets/icons/logo.svg?inline";

export default {
    name: "LoginModal",
    components: {
        Modal,
        ForgotPasswordModal,
        iconLeft,
        iconEye,
        iconEyeOff,
        Logo
    },
    data() {
        return {
            email: "",
            password: "",
            validation: {},
            showPassword: false,
            forgotPasswordModelOpen: false,
        };
    },
    methods: {
        sendCloseEvent() {
            this.$emit("close");
        },
        onEmailInput() {
            this.validation.email = null;
        },
        onPasswordInput() {
            this.validation.password = null;
        },
        async sendLoginRequest() {
            this.validate();
            if (Object.keys(this.validation).length > 0) {
                return;
            }

            try {
                const token = await Authentication.Login(
                    this.email,
                    this.password
                );

                LocalStorage.SetItem("sessionToken", token);
                await this.$store.dispatch("user/getUserInfo");

                document.body.classList.remove("modal-active");
                return this.$router.push("/");
            } catch (error) {
                this.$toast({ type: "error", message: error });
            }
        },
        validate() {
            this.validation = Validator.Validate({
                email: this.email,
                password: this.password
            }, {
                email: [Validator.NotEmpty, Validator.IsEmail],
                password: Validator.NotEmpty
            });

            if (Object.keys(this.validation).length > 0) {
                this.$toast({ type: "error", message: Object.values(this.validation)[0] });
            }
        },
    },
};
</script>

<style lang="scss">
@import "index";
</style>

