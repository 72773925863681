<template>
    <Modal @close="$emit('close')" class="login-form -center">
        <template v-slot:modal-header-button-start>
            <button @click="$emit('close')" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>
        <template v-slot:header-content>
            <Logo />
        </template>

        <div class="forgot-password" v-if="!success">
            <h1>{{ $t("Forgot password?") }}</h1>
            <form @submit.prevent="sendForgotPasswordRequest">
                <div class="field">
                    <div :class="`input ${validation.email ? 'has-error' : ''}`">
                        <div class="input-content">
                            <input
                                name="email"
                                :placeholder="$t('Email')"
                                class="input"
                                v-model="email"
                            />
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <button type="submit" class="btn -theme -lg">
                        {{ $t("Send") }}
                    </button>
                </div>
            </form>
        </div>

        <div class="thank-you" v-else>
            <h2>{{ $t("Thank you!") }}</h2>
            <p>
               {{ $t("We have sent you an email with instructions on how to reset your password.") }}
            </p>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Validator from "@/lib/Validator";
import UsersApi from "@/lib/api/Users";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import Logo from "@/assets/icons/logo.svg?inline";

export default {
    name: "ForgotPasswordModal",
    components: {
        Modal,
        iconLeft,
        Logo,
    },
    data() {
        return {
            success: false,
            email: "",
            validation: {},
        };
    },
    methods: {
        async sendForgotPasswordRequest() {
            this.validate();

            if (Object.keys(this.validation).length > 0) {
                return;
            }

            let resp = await UsersApi.ForgotPassword(this.email);

            if (!resp.success) {
                this.$toast({ type: "error", message: resp.message });
                return;
            }

            this.success = true;
        },
        validate() {
            this.validation = Validator.Validate({
                email: this.email,
            }, {
                email: [Validator.NotEmpty, Validator.IsEmail],
            });

            if (Object.keys(this.validation).length > 0) {
                this.$toast({ type: "error", message: Object.values(this.validation)[0] });
            }
        }
    },
};
</script>
