<template>
    <div class="login-view">
        <div class="login-container -no-padding">
            <Header class="-transparent">
                <template v-slot:button-start>
                    <iconLogo />
                </template>
            </Header>
            <Slider
                :navigation="false"
                :pagination="true"
                :stepper="false"
                :space-between="0">
                <template v-slot:[`slide-1`]>
                    <div class="slider-image featured-image">
                        <img src="@/assets/images/slide-1.jpeg" />
                        <div class="oval"></div>
                    </div>
                    <div class="slider-content">
                        <h2>{{ $t("Eating delicious food every day and losing weight in the meantime?") }}</h2>
                        <p>{{ $t("With the weekly menus and recipes of Project Gezond you will reach your ideal weight with a smile!") }}</p>
                    </div>
                </template>
                <template v-slot:[`slide-2`]>
                    <div class="slider-image featured-image">
                        <img src="@/assets/images/slide-2.jpeg" />
                        <div class="oval"></div>
                    </div>
                    <div class="slider-content">
                        <h2>{{ $t("Never difficult calculations, but always enjoy") }}</h2>
                        <p>{{ $t("We have worked out every daily menu completely for you. So you don't have to count or keep track of anything yourself.") }}</p>
                    </div>
                </template>
                <template v-slot:[`slide-3`]>
                    <div class="slider-image featured-image">
                        <img src="@/assets/images/slide-3.jpeg" />
                        <div class="oval"></div>
                    </div>
                    <div class="slider-content">
                        <h2>{{ $t("Lose 0.5 to 1 kilo per week without feeling hungry") }}</h2>
                        <p>{{ $t("Thanks to the six eating moments a day, you will never suffer from an enormous appetite.") }}</p>
                    </div>
                </template>
                <template v-slot:[`slide-4`]>
                    <div class="slider-image featured-image">
                        <img src="@/assets/images/slide-4.jpeg" />
                        <div class="oval"></div>
                    </div>
                    <div class="slider-content">
                        <h2>{{ $t("More than 250 easy and especially tasty recipes") }}</h2>
                        <p>{{ $t("You get access to over 250 delicious recipes with step-by-step instructions") }}</p>
                    </div>
                </template>
                <template v-slot:[`slide-5`]>
                    <div class="slider-image featured-image">
                        <img src="@/assets/images/slide-5.jpeg" />
                        <div class="oval"></div>
                    </div>
                    <div class="slider-content">
                        <h2>{{ $t("You get everything you need to lose weight successfully") }}</h2>
                        <p>{{ $t("Unlimited personal support, a friendly Facebook group, handy variation lists, a dashboard for your progress and many more extras.") }}</p>
                    </div>
                </template>
            </Slider>

            <div class="content -center">
                <button @click="loginModelOpen = true" class="btn -theme -lg">
                    {{ $t("Login") }}
                </button>
                <p>
                    {{ $t("Not yet a member of Project Healthy?") }}
                    <span @click="registerModel = true">
                        {{ $t("Click here") }}
                    </span>
                    {{ $t("and try it free for 14 days") }}
                </p>
            </div>
            <login-modal @close="loginModelOpen = false" v-if="loginModelOpen" />
            <register-modal
                @openLogin="registerModel = false; loginModelOpen = true"
                @close="registerModel = false"
                v-if="registerModel"
            />

            <thanks-for-register v-if="query.startsWith('bedankt')" />
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import ThanksForRegister from "./ThanksForRegister";

import Slider from "@/components/Slider";

import iconLogo from "@/assets/icons/logo-icon.svg?inline";

export default {
    components: {
        Header,
        LoginModal,
        RegisterModal,
        ThanksForRegister,
        Slider,
        iconLogo
    },
    data() {
        return {
            loginModelOpen: false,
            registerModel: false,
        };
    },
    computed: {
        query() {
            return this.$route.params.query;
        }
    },
    mounted() {
        this.loginModelOpen = this.query === "login";
    }
};
</script>

<style lang="scss">
@import "index";
</style>
