<template>
    <Modal @close="sendCloseEvent" class="register-form">
        <template v-slot:modal-header-button-start>
            <button @click="sendCloseEvent" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>
        <template v-slot:modal-header-button-end>
            <button class="btn -outline -shadow -login" v-if="!showThanks" @click="sendOpenLoginEvent">
                {{ $t("Login") }}
            </button>
        </template>
        <div v-if="showThanks" class="-center">
            <h2>{{ $t("Thanks") }}</h2>
            <p>
                {{
                    $t(
                        "We will send you a link by email to set up your personal passwords. Then you can get started right away."
                    )
                }}
            </p>
        </div>
        <div v-else class="-center">
            <h2>{{ $t("Try Project Gezond for 14 days for free!") }}</h2>
            <p>
                {{ $t("100% free and after 14 days we will leave you alone, unless of course you are convinced of Project Gezond!") }}
            </p>
        </div>
        <form v-if="!showThanks" @submit.prevent="sendRegisterRequest">
            <div class="field">
                <div :class="`input ${validation.firstName ? 'has-error' : ''}`">
                    <div class="input-content">
                        <input
                            @change="onFirstnameInput"
                            v-model="firstName"
                            name="firstName"
                            :placeholder="$t('Firstname')"
                            class="input"
                        />
                    </div>
                </div>
            </div>
            <div class="field">
                <div :class="`input ${validation.email ? 'has-error' : ''}`">
                    <div class="input-content">
                        <input
                            @change="onEmailInput"
                            v-model="email"
                            name="email"
                            :placeholder="$t('Email')"
                            class="input"
                        />
                    </div>
                </div>
            </div>
            <div class="actions -center">
                <button type="submit" class="btn -black -lg">
                    {{ $t("Confirm registration") }}
                </button>
            </div>
        </form>
    </Modal>
</template>

<script>
import Authentication from "@/lib/api/Authentication";
import Validator from "@/lib/Validator";
import Modal from "@/components/Modal";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    name: "RegisterModal",
    components: {
        Modal,
        iconLeft,
    },
    data() {
        return {
            email: "",
            firstName: "",
            validation: {},
            showThanks: false,
        };
    },
    methods: {
        sendCloseEvent() {
            this.showThanks = false;
            this.$emit("close");
        },
        sendOpenLoginEvent() {
            this.showThanks = false;
            this.$emit("openLogin");
        },
        onEmailInput() {
            this.validation.email = null;
        },
        onFirstnameInput() {
            this.validation.firstName = null;
        },
        async sendRegisterRequest() {
            this.validate();
            if (Object.keys(this.validation).length > 0) {
                return;
            }

            try {
                await Authentication.Register(this.email, this.firstName);
                this.showThanks = true;
            } catch (error) {
                this.$toast({ type: "error", message: error.email[0] });
            }
        },
        validate() {
            this.validation = Validator.Validate({
                firstName: this.firstName,
                email: this.email,
            }, {
                firstName: Validator.NotEmpty,
                email: [Validator.NotEmpty, Validator.IsEmail],
            });

            if (Object.keys(this.validation).length > 0) {
                this.$toast({ type: "error", message: Object.values(this.validation)[0] });
            }
        },
    },
};
</script>

<style lang="scss">
@import "index";
</style>
