import HTTPClient from "@/lib/HTTPClient";

const Authentication = {};

Authentication.Login = async function(email, password) {
    let {data, status} = await HTTPClient.Post("/login", {
        data: {
            email,
            password,
            testMode: false // required for now
        }
    });

    if (status != 200) {
        throw data.error || data.errors;
    }

    return data.accesToken;
};

Authentication.Register = async function(email, firstName) {
    let {data, status} = await HTTPClient.Post("/register", {
        data: {
            first_name: firstName,
            email: email,
        }
    });

    if (status != 200) {
        throw data;
    }

    return data;
};

export default Authentication;