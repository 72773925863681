<template>
    <modal @close="sendCloseEvent()" class="login-form -center">
        <template v-slot:modal-header-button-start>
            <button @click="sendCloseEvent()" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>
        <template v-slot:header-content>
            <Logo />
        </template>

        <h2>{{ $t("Welcome!") }}</h2>
        <p>{{ $t("Your password is set, you are ready to login!") }}</p>
        <button class="btn -theme -lg" @click="gotoLogin()">{{ $t("Login") }}</button>
    </modal>
</template>

<script>
import Modal from "@/components/Modal";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import Logo from "@/assets/icons/logo.svg?inline";

export default {
    components: {
        Modal,
        iconLeft,
        Logo
    },
    methods: {
        gotoLogin() {
            this.$router.push("/welkom/login");
        },
        sendCloseEvent() {
            this.$router.push("/welkom");
        }
    }
};
</script>
